export const title = "About Us"

export const par1 = "At BAH Career Training, we are licensed through both the Texas Workforce Commission Career Schools and Colleges and the DADS."+
" We are an approved testing center, and we are accredited by the National Health Careers Association."+
" Additionally, BAH Career Training is approved via the TWC, also known as the Texas Workforce commission, and Career Schools and Colleges through the WIA and DARS programs."

export const par2 = "At BAH Career Training, we offer students support in addition to great career training."+
" We offer payment plans to help make BAH Career Training a more affordable option."+
" Our payment plans vary based upon student situation and courses chosen."+
" We provide job placement assistance to our students by helping match them with an employer based on the student’s career choice and skill strengths."+
" We also offer job search assistance, resume building, and help with interview skills."

export const par3 = "We are committed to providing an equal opportunity environment for our students and staff."+
" We never discriminate based on race, sex, religion, sexual preference, or age."+
" We provide students with an educational environment that protects the rights of individuals, and we do so by promoting diversity and encouraging mutual respect towards all people without discrimination."

export const par4 = "We are offering career training and certification testing at our campus in Garland, TX." +
"\"" + "YOUR FUTURE WAITS FOR YOU HERE AT BAH CAREER TRAINING INC!" + "\"" + " So, come by and learn more about how you can start training for your new future."