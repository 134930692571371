import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from "@mui/material/Typography";
import * as AboutVar from './About.js';
import Divider from '@mui/material/Divider';
import CustomTitle from "../../components/CustomTitle.jsx";
import ReactPlayer from 'react-player'
import OfficeHours from './components/OfficeHours.jsx';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import YouTube from 'react-youtube';
import * as Themes from '../../components/themes/Themes.js';
import MyHeaders from '../../components/headers/MyHeaders.jsx';
import MyFooter from '../../components/footers/MyFooter.jsx';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function About() {

  const libraries = ['places'];
  const mapContainerStyle = {
    width: '100vw',
    height: '100vh'
  };
  const center = {
    lat: 7.2905715, // default latitude
    lng: 80.6337262, // default longitude
  };

  return (
    <div><MyHeaders/>
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} style={Themes.background_logo_bw.main}>
        <Grid xs={8}>

        <CustomTitle title = {AboutVar.title} heading = "h4" align="left"/>

          <Item style={Themes.background_logo_bw.background_0}>

            {/*<Divider />*/}

            
            
            
                <Typography variant="body1" color="text.primary" align="left" sx={{m: 1 }}>
                <Themes.WhiteTextTypography><h3>Who are we?</h3></Themes.WhiteTextTypography>
                </Typography>
                <Typography variant="body2" color="text.secondary" align="left" sx={{m: 1 }}>
                <Themes.WhiteTextTypography>{AboutVar.par1}</Themes.WhiteTextTypography>
                </Typography>
                <Typography variant="body2" color="text.secondary" align="left" sx={{m: 1 }}>
                <Themes.WhiteTextTypography>{AboutVar.par2}</Themes.WhiteTextTypography>
                </Typography>
                <Typography variant="body2" color="text.secondary" align="left" sx={{m: 1 }}>
                <Themes.WhiteTextTypography>{AboutVar.par3}</Themes.WhiteTextTypography>
                </Typography>
                <Typography variant="body2" color="text.secondary" align="left" sx={{m: 1 }}>
                <Themes.WhiteTextTypography>{AboutVar.par4}</Themes.WhiteTextTypography>
                </Typography>

          </Item>

          {/*<Item>
            <Typography variant="body1" color="text.primary" align="left">
              <h3>Locations</h3>
            </Typography>
  </Item>*/}

  ...


        </Grid>
        <Grid xs={4}>
          <Item style={{backgroundColor: 'rgba(52, 52, 52, 0)'}}> <OfficeHours /></Item>

          {/*<ReactPlayer url='https://www.youtube.com/watch?v=LKdSxFMqz4A' />*/}
        </Grid>
      </Grid>
    </Box>

    <MyFooter/>
    </div>
  );
}

/*import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box } from "@mui/material";
import * as AboutVar from './About.js';

export default function About() {
  return (
      <Box
      sx={{
        width: '100%',
        height: '140px',
        color: '#fff',
        '& > .MuiBox-root > .MuiBox-root': {
          p: 1,
          borderRadius: 2,
          fontSize: '0.875rem',
          fontWeight: '700',
        },
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: 1,
          gridTemplateRows: 'auto',
          gridTemplateAreas: `"main main main sidebar"`,
        }}
      >
        <Box sx={{ gridArea: 'main', bgcolor: 'secondary.main' }}>
            <Container maxWidth="lg" sx={{ m: 0 }}>
              <Typography variant="body1" color="text.primary" align="left">
                <h3>{AboutVar.title}</h3>
                </Typography>
                <Typography variant="body2" color="text.secondary" align="left">
                  {AboutVar.par1}
                </Typography>
                <Typography variant="body2" color="text.secondary" align="left">
                  {AboutVar.par2}
                </Typography>
                <Typography variant="body2" color="text.secondary" align="left">
                  {AboutVar.par3}
                </Typography>
                <Typography variant="body2" color="text.secondary" align="left">
                  {AboutVar.par4}
                </Typography>
            </Container>
          </Box>
        <Box sx={{ gridArea: 'sidebar', bgcolor: 'error.main' }}>
          Contact Us:
          Buisness Hours:
        </Box>
      </Box>
    </Box>
  );
}*/