import * as React from 'react';
import ProfilePage from '../components/ProfilePage.jsx';
import * as CatalogVar from '../components/Catalogs.js';
import MyHeaders from '../../../components/headers/MyHeaders.jsx';
import MyFooter from '../../../components/footers/MyFooter.jsx';

export default function Programs_CMT() {

  return (
    <>
        <ProfilePage title={CatalogVar.title_cmt} description={CatalogVar.description_cmt} requirements={CatalogVar.requirement_cmt} duration={CatalogVar.duration_cmt} imgURL='/catalogs/ComputerMaintenanceTechnician.jpg'/>
    </>
  );
}