import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as HomeVar from './Home.js';
import { withStyles } from "@material-ui/core/styles";
import CustomTitle from '../../../components/CustomTitle.jsx';
import Divider from '@mui/material/Divider';
import * as Themes from '../../../components/themes/Themes.js';

export default function IntroductionPanel() {
      /*return (

        <>
        <CustomTitle title = {HomeVar.Title} heading = "h4" align="left"/>
        <Divider sx={{ bgcolor: "secondary.light" }}/>
        <Typography variant="body2" color="text.secondary" align="left">
        {HomeVar.introduction}
        </Typography>
        </>
      );*/

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={Themes.background_logo_bw.background_0}>
      <CustomTitle title = {HomeVar.Title} heading = "h4" align="left"/>
      <Divider sx={{ bgcolor: "secondary.light" }}/>
        <Typography variant="body2" color="white" m={1} align="left">
          <p>{HomeVar.introduction}</p>
        </Typography>
      </AppBar>
    </Box>
  );
}