import React from "react"
export default function RouteValues(props) {}

export const home = '/'
export const programs = '/programs'
export const programs_cmt = programs + '/computer-maintenance-technician'
export const programs_da = programs + '/dental-assistant'
export const programs_ekgt = programs + '/ekg-technician'
export const programs_hit = programs + '/health-information-technology'
export const programs_natp = programs + '/nurses-aid-training-program'
export const programs_pct = programs + '/patient-care-technician'
export const programs_pt = programs + '/phlebotomy-technician'
export const programs_cfat = programs + '/cpr-first-aid-training'
export const about = '/about'
export const contact_us = '/contact-us'