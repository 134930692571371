import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter, YouTube, WhatsApp } from "@mui/icons-material";
import { Box } from "@mui/material";
import { styled } from '@mui/system';

export default function MyFooter() {

  const xsValue = 12 //12
  const smValue = 3 //4
  const maxWidth = 'lg' //lg

  const BottomSnap = styled('div')({
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: 300,
    textAlign: 'center',
});

  return (
    <>
    {/*<BottomSnap>*/}
    <Box
      component="footer"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        p: 6,
      }}

      /*style={{ backgroundImage: `url("./background/Background_1.jpg")`}}*/
    >
      <Container maxWidth={maxWidth}>
        <Grid container spacing={3}>

          <Grid item xs={xsValue} sm={smValue}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Africa Location
            </Typography>
            <Typography variant="body2" color="text.secondary">
              BAH Career Training Affiliated Rising Star Academy Bilingual Highschool Kumba - Cameroon
              <p>237-677759149</p>
              <Link href="https://www.youtube.com/@bahcareertrainingcenter.ri4521"  target="_blank" color="inherit">
              <YouTube htmlColor="red"/>
            </Link>
            </Typography>
          </Grid>

          <Grid item xs={xsValue} sm={smValue}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body2" color="text.secondary">
              BAH Career Training
            </Typography>
            <Typography variant="body2" color="text.secondary">
              2433 Goldfinch ln, Garland, TX 
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Phone: +1 (972)272-5820
            </Typography>
          </Grid>

          <Grid item xs={xsValue} sm={smValue}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Follow Us
            </Typography>
            <Link href="https://www.facebook.com/people/BAH-Career-Training/100066675153766/" target="_blank" color="inherit">
              <Facebook htmlColor="blue"/>
            </Link>
            <Link
              href="https://www.yelp.com/biz/bah-career-training-center-garland-4"
              color="inherit"
              target="_blank"
              sx={{ pl: 1, pr: 1 }}
            >
              <Instagram htmlColor="purple"/>
            </Link>
            <Link href="https://www.twitter.com/"  target="_blank" color="inherit">
              <Twitter color="primary"/>
            </Link>
            {/*<Link href="https://wa.me/+1214--"  target="_blank" color="inherit">
              <WhatsApp htmlColor="green"/>
            </Link>*/}
          </Grid>

          <Grid item xs={xsValue} sm={smValue}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Partners
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <li><Link href="https://home.pearsonvue.com/" color="inherit" target="_blank">Pearson View</Link></li>
              <li><Link href="https://www.twc.texas.gov/" color="inherit" target="_blank">Texas Workforce Comission</Link></li>
              <li> Affiliated With Rising Star Academy</li>
            </Typography>
          </Grid>
          
        </Grid>
        <Box mt={5}>

        <Typography variant="body2" color="text.secondary" align="center">
            
            <Link color="inherit" target="_blank" href="https://www.careertraininggarland.com/wp-content/uploads/student_complaint_policy.jpg">
                STUDENT COMPLAINT POLICY
              </Link>
            </Typography>

          <Typography variant="body2" color="text.secondary" align="center">

            {"© Copyright 2014 All rights reserved"}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    </Box>
    {/*</BottomSnap>*/}
    </>
  );
}