import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box, CardMedia } from "@mui/material";
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import { Paper, Card, CardContent } from '@mui/material';
import { withStyles } from '@mui/styles';
import CustomTitle from "../../components/CustomTitle.jsx";

import * as HomeVar from './components/Home';
import ProgramListPanel from "./components/ProgramListPanel.jsx";
import IntroductionPanel from "./components/IntroductionPanel.jsx";
import ProgramsPanel from "./components/ProgramsPanel.jsx";
import ServicePanel from "./components/ServicePanel.jsx";
import * as Themes from "../../components/themes/Themes.js";
import Image from "material-ui-image";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MyHeaders from "../../components/headers/MyHeaders.jsx";
import MyFooter from "../../components/footers/MyFooter.jsx";

//import ComputerMaintenanceTechnician from process.env.PUBLIC_URL + '/catalogs/NurseAideTraining.png'
//import NurseAideTraining from "../../../catalogs/NurseAideTraining.png";
//import {NurseAideTraining} from "../../../public/catalogs/NurseAideTraining.png";

const defaultTheme = createTheme();

export default function Home() {

  return (
    <div >

    <MyHeaders/>

    <Box sx={{ flexGrow: 1}} style={Themes.background_logo_bw.main} p={2}>

        <br></br>
        
        <ProgramListPanel/>

        <IntroductionPanel/>

        <br></br>

        <ProgramsPanel/>

        <br></br>

        <ServicePanel/>

        <br></br>

        <Grid container spacing={0} direction="column" alignItems="center" /*style={{ minHeight: '100vh' }}*/ >
            <Grid item>
                <Card>
                    <CardMedia component="img" alt="green iguana" height='75%' width='75%' image='/ads/img_0.jpg'/>
                </Card>
            </Grid>      
        </Grid>

        <br></br>
    </Box>

    <MyFooter/>

    </div>
  );
}

/*
    <Box component="content" sx={{ m: 0 }}>
      <Container maxWidth="lg" sx={{ m: 0 }}>

        <Typography variant="body1" color="text.primary" align="left">
          <h3>Welcome</h3>
          <Divider />
          <h3>{HomeVar.Title}</h3>
        </Typography>
        <Typography variant="body2" color="text.secondary" align="left">
          {HomeVar.Description}
        </Typography>

        <Typography variant="body1" color="text.primary" align="left">
          <h3>{HomeVar.title_1}</h3>
        </Typography>
        <Typography variant="body2" color="text.secondary" align="left">
          {HomeVar.description_1.map((item,i) => <li key={i}>{item}</li>)}
        </Typography>

        <Typography variant="body1" color="text.primary" align="left">
          <h3>{HomeVar.title_2}</h3>
        </Typography>
        <Typography variant="body2" color="text.secondary" align="left">
          {HomeVar.description_2.map((item,i) => <li key={i}>{item}</li>)}
        </Typography>

        <Typography variant="body1" color="text.primary" align="left">
          <h3>{HomeVar.title_3}</h3>
        </Typography>
        <Typography variant="body2" color="text.secondary" align="left">
          {HomeVar.description_3.map((item,i) => <li key={i}>{item}</li>)}
        </Typography>

        <Typography variant="body2" color="text.secondary" align="left">
          <p>{HomeVar.summary_1}</p>
          <p>{HomeVar.summary_2}</p>
          <p>{HomeVar.summary_3}</p>
        </Typography>

      </Container>
    </Box>
*/