import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import MyHeaders from './components/headers/MyHeaders.jsx'
import Home from "./pages/home/Home.jsx";
import Programs from "./pages/programs/Programs.jsx";
import About from "./pages/about/About.jsx";
import MyFooter from './components/footers/MyFooter.jsx'
import * as RoutesVar from './components/RouteValues.js';
import Programs_CMT from './pages/programs/pages/Programs_CMT.jsx';
import Programs_DA from './pages/programs/pages/Programs_DA.jsx';
import Programs_EKGT from './pages/programs/pages/Programs_EKGT.jsx';
import Programs_HIT from './pages/programs/pages/Programs_HIT.jsx';
import Programs_NATP from './pages/programs/pages/Programs_NATP.jsx';
import Programs_PCT from './pages/programs/pages/Programs_PCT.jsx';
import Programs_PT from './pages/programs/pages/Programs_PT.jsx';
import Programs_CFAT from './pages/programs/pages/Programs_CFAT.jsx';
import ContactUs from './pages/contact_us/ContactUs.jsx'

function App() {
  return (
    <Router>
          <Routes>
            <Route path={RoutesVar.home} element={<Home />} />
            <Route path={RoutesVar.programs} element={<Programs />} />
            <Route path={RoutesVar.programs_cmt} element={<Programs_CMT />} />
            <Route path={RoutesVar.programs_da} element={<Programs_DA />} />
            <Route path={RoutesVar.programs_ekgt} element={<Programs_EKGT />} />
            <Route path={RoutesVar.programs_hit} element={<Programs_HIT />} />
            <Route path={RoutesVar.programs_natp} element={<Programs_NATP />} />
            <Route path={RoutesVar.programs_pct} element={<Programs_PCT />} />
            <Route path={RoutesVar.programs_pt} element={<Programs_PT />} />
            <Route path={RoutesVar.programs_cfat} element={<Programs_CFAT />} />
            <Route path={RoutesVar.about} element={<About />} />
            <Route path={RoutesVar.contact_us} element={<ContactUs />} />
          </Routes>
    </Router>
  );
}
export default App;