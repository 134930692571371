import React from "react";
import Typography from '@mui/material/Typography';
import { withStyles } from "@material-ui/core/styles";

export default function Themes(){}

export const background_logo_bw = {
    main: {
        //backgroundImage: `url("./LogoBW.png")`,
        //backgroundColor: 'rgba(0, 0, 0, 0.8)'
        //backgroundImage: `url("./background/Background_3.jpg")`
        backgroundImage: 'url(' + require('../../background/Background_3.jpg') + ')'
      },
    background_0: {
        //backgroundImage: `url("./background/Background_0.jpg")`
        backgroundImage: 'url(' + require('../../background/Background_0.jpg') + ')'
    }
  };

export const WhiteTextTypography = withStyles({
    root: {
      color: "#FFFFFF"
    }
  })(Typography);