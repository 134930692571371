import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

const styles = {
  h4: {
    //color: '#6a500f'
    color: '#e5c308'
  }
};

const CustomTypography = withStyles(styles)(Typography);

const CustomTitle = (props) => {
  return <CustomTypography variant={props.heading} align={props.align} sx={{m: 1 }}>{props.title}</CustomTypography>;
}

export default CustomTitle;