import * as React from 'react';
import ProfilePage from '../components/ProfilePage.jsx';
import * as CatalogVar from '../components/Catalogs.js';
import MyHeaders from '../../../components/headers/MyHeaders.jsx';
import MyFooter from '../../../components/footers/MyFooter.jsx';

export default function Programs_HIT() {

  return (
    <>
      <ProfilePage title={CatalogVar.title_hit} description={CatalogVar.description_hit} requirements={CatalogVar.requirement_hit} duration={CatalogVar.duration_hit} imgURL='/catalogs/HealthInformationTechnology.jpg'/>
    </>
  );
}