import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from "react-router-dom";
import * as Themes from '../themes/Themes';
import * as RouteValues from '../RouteValues';

function MyHeaders() {

  return (
    <React.Fragment>
      <AppBar
        component="div"
        //color="primary"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
        style={Themes.background_logo_bw.background_0}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={1.5}>
              <RouterLink to={RouteValues.home}>
                <img src="/Logo.png" width={250} height={175} alt="image"/>
              </RouterLink>
            </Grid>
            <Grid item>
              <Typography color="white" variant="h3" component="h1" align='left'>
                  Bah Career Training Center- Garland
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        <Typography align = 'right'>
              <Themes.WhiteTextTypography m={1}>
                <Button color="inherit" component={RouterLink} to={RouteValues.home}>Home</Button>
                <Button color="inherit" component={RouterLink} to={RouteValues.programs}>Programs</Button>
                <Button color="inherit" component={RouterLink} to={RouteValues.about}>About</Button>
              </Themes.WhiteTextTypography>
            </Typography>
      </AppBar>
    </React.Fragment>
  );
}

export default MyHeaders;

/*import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { BrowserRouter as Router, Link as RouterLink } from "react-router-dom";
import * as Themes from '../themes/Themes';
import * as RouteValues from '../RouteValues';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export default function MyHeaders() {
  return (
    <Box sx={{ flexGrow: 1 }} style={Themes.background_logo_bw.background_0}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={2}>

            <RouterLink to={RouteValues.home}>
              <img src="/Logo.png" width={250} height={175} alt="image"/>
            </RouterLink>

        </Grid>
        <Grid item xs={6} md={8}>

            <Typography variant="h3" component="div" align='left'>
              Bah Career Training Center- Garland
            </Typography >

        </Grid>
        <Typography align = 'right'>
              <Themes.WhiteTextTypography m={1}>
                <Button color="inherit" component={RouterLink} to={RouteValues.home}>Home</Button>
                <Button color="inherit" component={RouterLink} to={RouteValues.programs}>Programs</Button>
                <Button color="inherit" component={RouterLink} to={RouteValues.about}>About</Button>
              </Themes.WhiteTextTypography>
            </Typography>
      </Grid>
    </Box>
  );
}*/

/*import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { BrowserRouter as Router, Link as RouterLink } from "react-router-dom";
import * as Themes from '../themes/Themes';
import * as RouteValues from '../RouteValues';

export default function MyHeaders() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={Themes.background_logo_bw.background_0}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >

          <RouterLink to={RouteValues.home}>
            <img src="/Logo.png" width={250} height={175} alt="image"/>
          </RouterLink>
            
          </IconButton>
          <Typography variant="h3" component="div" sx={{ flexGrow: 1 }}>
            Bah Career Training Center- Garland
          </Typography >
        </Toolbar>

        <Typography align='right'>
          <Themes.WhiteTextTypography m={1}>
            <Button color="inherit" component={RouterLink} to={RouteValues.home}>Home</Button>
            <Button color="inherit" component={RouterLink} to={RouteValues.programs}>Programs</Button>
            <Button color="inherit" component={RouterLink} to={RouteValues.about}>About</Button>
            {/*<Button color="inherit" component={RouterLink} to={RouteValues.contact_us}>Contact Us</Button>*///}
          /*</Themes.WhiteTextTypography>
        </Typography>

      </AppBar>
    </Box>
  );
}*/

/*import * as React from 'react';
import Box from '@mui/material/Box';
import {
	AppBar,
	Toolbar,
	CssBaseline,
	Typography,
  } from "@material-ui/core";
  import Link from "@mui/material/Link";
  import { BrowserRouter as Router, Link as RouterLink } from "react-router-dom";
  import { makeStyles } from '@material-ui/core/styles' 
  
export default function MyHeaders() {

  return (
    <div style={{ width: '100%' }}>
      <Box component="header" sx={{backgroundColor: (theme) => theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[800], p: 6,}}>
        <img src="/Logo.png" alt="image" />
        <h1>Bah Career Training - Garland</h1>

        <Typography align="right">
          <Link component={RouterLink} to="/">Home</Link>{" "}
          <Link component={RouterLink} to="/catalogs">Catalogs</Link>{" "}
          <Link component={RouterLink} to="/about">About</Link>
        </Typography>
      </Box>
    </div>
  );
}*/



/*import * as React from "react";
import Container from "@mui/material/Container";
//import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
//import { makeStyles } from "@mui/styles";
import { makeStyles } from '@material-ui/core/styles' 
import {
	AppBar,
	Toolbar,
	CssBaseline,
	Typography,
  } from "@material-ui/core";
  import { BrowserRouter as Router, Link as RouterLink } from "react-router-dom";

export default function MyHeaders() {

	const useStyles = makeStyles((theme) => ({
		navlinks: {
		  marginLeft: theme.spacing(10),
		  display: "flex"
		},
	   logo: {
		  flexGrow: "1",
		  cursor: "pointer",
		},
		link: {
		  textDecoration: "none",
		  color: "white",
		  fontSize: "20px",
		  marginLeft: theme.spacing(20),
		  "&:hover": {
			color: "yellow",
			borderBottom: "1px solid white",
		  },
		},
	  }));

	  const classes = useStyles();
  return (
	
    <Box
      component="header"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        p: 6,
      }}
    >

      <Container maxWidth="lg" sx={{ m: 0 }}>
		<Toolbar>
		<img src="/Logo.png" alt="image" />
			<Typography variant="h4" className={classes.logo}>
			Bah Career Training - Garland
			</Typography>
			<Typography align="right">
			<div className={classes.navlinks}>
				<Link component={RouterLink} to="/" className={classes.link}>Home</Link>
				<Link component={RouterLink} to="/catalogs" className={classes.link}>Catalogs</Link>
				<Link component={RouterLink} to="/about" className={classes.link}>About</Link>
			</div>
			</Typography>
		</Toolbar>

      </Container>
    </Box>
  );
}*/

/*import React from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(10),
    display: "flex",
  },
 logo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: theme.spacing(20),
    "&:hover": {
      color: "yellow",
      borderBottom: "1px solid white",
    },
  },
}));

function MyHeaders() {
  const classes = useStyles();

  return (
    <AppBar position="static">
      <CssBaseline />
      <Toolbar>
        <Typography variant="h4" className={classes.logo}>
		<img src="/Logo.png" alt="image" />
          Bah Career Training - Garland
        </Typography>
          <div className={classes.navlinks}>
            <Link to="/" className={classes.link}>Home</Link>
            <Link to="/catalogs" className={classes.link}>Catalogs</Link>
			<Link to="/about" className={classes.link}>About</Link>
          </div>
      </Toolbar>
    </AppBar>
  );
}
export default MyHeaders*/

/*import * as React from 'react';
import Button from '@mui/material/Button';
import "./MyHeaders.css"
import { Outlet, Link } from "react-router-dom";

export default function MyHeaders() {
  return (
	<div>
		<div>
			<img src="/logo192.png" alt="image" />
		</div>
		<nav>
			<div className='align-right'>
				<Button variant="contained" color="primary"><Link to="/">Home</Link></Button>
				<Button variant="contained" color="primary"><Link to="/catalogs">Catalog</Link></Button>
				<Button variant="contained" color="primary" to = "/about"><Link to="/about">About</Link></Button>
			</div>
		</nav>
	</div>
  );
}*/