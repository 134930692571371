import * as React from 'react';
import ProfilePage from '../components/ProfilePage.jsx';
import * as CatalogVar from '../components/Catalogs.js';
import MyHeaders from '../../../components/headers/MyHeaders.jsx';
import MyFooter from '../../../components/footers/MyFooter.jsx';

export default function Programs_CMT() {

  return (
    <>
      <ProfilePage title={CatalogVar.title_cfat} description={CatalogVar.description_cfat} requirements={CatalogVar.requirement_cfat} duration={CatalogVar.duration_cfat} imgURL='/catalogs/CPRTraining.jpg'/>
    </>
  );
}

/*import * as React from 'react';
import ProfilePage from '../components/ProfilePage.jsx';
import * as CatalogVar from '../components/Catalogs.js';
import Grid from '@mui/material/Grid';
import * as Themes from '../../../components/themes/Themes.js';

export default function Programs_CFAT() {

  return (
    <>
        <Grid container spacing={2} style={Themes.background_logo_bw.main}>

          <ProfilePage title={CatalogVar.title_cfat} description={CatalogVar.description_cfat} requirements={CatalogVar.requirement_cfat} duration={CatalogVar.duration_cfat} imgURL='/catalogs/CPRTraining.jpg'/>

          <img src = '/program_cpr/CPR_IMG_0.png'/>
        </Grid>
    </>
  );
}*/