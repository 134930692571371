import * as React from 'react';
import ProfilePage from '../components/ProfilePage.jsx';
import * as CatalogVar from '../components/Catalogs.js';
import MyHeaders from '../../../components/headers/MyHeaders.jsx';
import MyFooter from '../../../components/footers/MyFooter.jsx';

export default function Programs_PCT() {

  return (
    <>
      <ProfilePage title={CatalogVar.title_pct} description={CatalogVar.description_pct} requirements={CatalogVar.requirement_pct} duration={CatalogVar.duration_pct} imgURL='/catalogs/PatientCareTechnician.png'/>
    </>
  );
}