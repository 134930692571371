import React from "react"
export default function Catalogs(){
}

export const duration = 'Duration:'
const dur_0 = 'Contact us for more information'
const dur_1 = '--'

export const requirements = 'Requirements:'
const req_0 = 'Contact us for more information'

//Programs
export const title_cmt = "Computer Maintenance Technician"
const description_cmt_1 = "Everybody with a computer knows that moment of panic when the hard drive crashes--and for business people, it usually happens when they're staring into the eye of a deadline. But if you're a computer physician capable of diagnosing virtual ills and then repairing them, you can take on the appearance of an angel of mercy as a computer repair specialist. You'll drive out to clients' homes or offices with your briefcase of tools and equipment and heal those sickly computers and printers. You can also earn tidy revenues with preventive maintenance--dusting or vacuuming innards and cleaning disk drives on a quarterly or semiannual basis. The advantages to this business are that it's recession-proof--businesses need computers to operate, and home-computer buffs can't live without their virtual pals, either. And since the computer industry is one of exponential growth, peripheral industries like computer maintenance and repair are here to stay. As a final plus, this business is satisfying--people are always appreciative when you bring an acutely ill hard drive or printer back to health. You'll need to have the knowledge and skills of a good computer physician, including how to change motherboards, repair and replace hard drives, and add memory chips. And you should have a good computer-side manner so you can soothe panicky owners and tactfully instill the benefits of good preventive maintenance.";
const description_cmt_2 = "";
const description_cmt_3 = "";
const description_cmt_4 = "";
export const description_cmt = [description_cmt_1, description_cmt_2, description_cmt_3, description_cmt_4]
export const requirement_cmt = [req_0]
export const duration_cmt = [dur_0, dur_1]

export const title_da = "Dental Assistant"
const description_da_1 = "The dental assistant performs many tasks requiring both interpersonal and technical skills. Although state regulations vary, responsibilities may include: assisting the dentist during a variety of treatment procedures. taking and developing dental radiographs (x-rays)";
const description_da_2 = "";
const description_da_3 = "";
const description_da_4 = "";
export const description_da = [description_da_1, description_da_2, description_da_3, description_da_4]
export const requirement_da = [req_0]
export const duration_da = [dur_0, dur_1]

export const title_ekgt = "EKG Technician"
const description_ekgt_1 = "An EKG technician, also known as an electrocardiogram technician or a cardiology technologist, monitors and tests a patient's cardiovascular performance by using EKG equipment. At BAH Career Training, we offer an EKG Technician Program that is designed to prepare you for the large employment growth in private laboratories and doctor's offices, as well as hospitals, and provide direct patient care. EKG technicians can have a very dynamic working environment, which involves working with patients from all types of backgrounds.";
const description_ekgt_2 = "Our EKG technician program will prepare you to monitor EKG rhythms for patients who experience heart conditions, during physical exams, or in preparation for surgery. Our students will receive specialized training in how to operate equipment used to measure a patient’s heart performance. By attaching electrodes to a patient’s body and recording the electrical impulses transmitted by a patient’s heart with an EKG, the technician provides the data to the patient’s physician for later analysis.";
const description_ekgt_3 = "In addition to our professional EKG training, we also offer our students affordable payment plan options to help them with the cost of our EKG technician classes. We also offer help securing a job after graduation by providing our students with resume preparation, interviewing skills training, and job placement assistance upon graduation.";
const description_ekgt_4 = "Call us today for more information regarding our EKG technician program in Garland, TX!";
export const description_ekgt = [description_ekgt_1, description_ekgt_2, description_ekgt_3, description_ekgt_4]
export const requirement_ekgt = [req_0]
export const duration_ekgt = [dur_0, dur_1]

export const title_hit = "Health Information Technology"
const description_hit_1 = "The Health Information Tech course offered by Bah Career Training Center in Garland will introduce the students to Electronic Health Record and a combination of medical knowledge, a business application for any setting within the health care industry from hospitals, ambulatory, long-term, home health, specialty, physician practices, population health, and practical understanding of what an electronic health record specialist is.";
const description_hit_2 = "Health Information Technology. A Registered Health Information Technician (or RHIT) plays an important role in ensuring patient record accuracy, organization, quality, security and accessibility. These professionals are also referred to as medical record technicians.";
const description_hit_3 = "";
const description_hit_4 = "";
export const description_hit = [description_hit_1, description_hit_2, description_hit_3, description_hit_4]
export const requirement_hit = [req_0]
export const duration_hit = [dur_0, dur_1]

export const title_natp = "Nurses Aid Training Program"
const description_natp_1 = "The growing percentage of aging adults has caused the CNA field to grow. As the number of older adults needing care expands, so will the demand for CNAs. At BAH Career Training, we offer CNA career training to help students train for jobs in this expanding field. Our instructors provide students with hands-on training and with real world insight to better prepare them for their new careers as CNAs.";
const description_natp_2 = "Our CNA training program educates students on the basic skills and abilities that are needed to perform the essential functions of working in this fast paced and exciting field. Our students will learn how to care for disabled and elderly clients, as well as how to assist nurses with various functions and tasks. Students will learn how to work alongside doctors and nurses and provide exceptional assistance.";
const description_natp_3 = "The CNA training program here ensures that students are able to skillfully perform and function in a variety of setting such as: skilled nursing facilities, hospitals, long term care facilities, private home care, and hospices. Upon completion of the CNA training course, students are required to take the state certification test in order to be eligible for any type of employment as a CNA. We require students to take this certification test at the end of their term, and certification testing is offered right here at BAH Career Training.";
const description_natp_4 = "We are currently offering several CNA classes, including our CNA training program course and our 24 hour CNA continuing education course. Stop by our campus in Garland, TX today to discuss our exciting CNA training program, and how BAH Career Training can get you on your way to a new and exciting career.";
export const description_natp = [description_natp_1, description_natp_2, description_natp_3, description_natp_4]
export const requirement_natp = [req_0]
export const duration_natp = [dur_0, dur_1]

export const title_pct = "Patient Care Technician"
const decription_pct_1 = "The patient care technician career training at BAH Career Training will prepare students to assist doctors and nurses in doctor’s offices, hospitals, nursing homes, and hospices. Our instructors will teach students all of the vital aspects of becoming a patient care technician. The main focus of this program is to prepare our students to become patient care technicians in large medical facilities, such as hospitals and hospices."
const decription_pct_2 = "A patient care technician is responsible for many supportive tasks in the medical field. Some tasks that a patent care technician may be ask to perform are:"
const decription_pct_3 = "Our patient care classes will teach students how to operate safely and effectively in this supportive role. To successfully pass this course, students will be expected to demonstrate that they can perform all necessary functions of this job, such as: providing supportive and competent assistance for other medical professionals, and ensuring the safety of patients and other staff members."
const decription_pct_4 = "Upon completion of this course, we provide job placement assistance to help our new graduates find employment as a patient care technician. We also provide assistance with resume writing and interviewing skills to help ensure the employment success of our students. We are signing up new students for our upcoming patient care classes now at our Garland, TX campus. Stop by today and learn more about how our career training can have you working in a great new medical career."
export const description_pct = [decription_pct_1, decription_pct_2, decription_pct_3, decription_pct_4]
export const requirement_pct = [req_0]
export const duration_pct = [dur_0, dur_1]

export const title_pt = "Phlebotomy Technician"
const description_pt_1 = "Phlebotomists work in a number of medical facilities and are a vital part of any medical team. As a phlebotomist, you will be required to draw the blood of patients and process specimen samples. A phlebotomist must have skill, compassion, and competence in order to be successful. At BAH Career Training we can teach you all you need to know and provide you with hand-on training to ensure your are successful in this field."
const description_pt_2 = "Our phlebotomy classes are designed to teach to students the basics of both types of blood drawing techniques, common and specialty. Our phlebotomy training will also teach students proper specimen processing and blood drawing safety. Upon completion of the course, our students will possess all of the skills they will need to work in a lab."
const description_pt_3 = "We offer comprehensive phlebotomy career training that will help ensure students are ready for their new careers as phlebotomist technicians. Our phlebotomy training shows students how to safely handle specimens from patients with a range health conditions and backgrounds and will allow students to practice these skills in a controlled environment with an experienced instructor."
const description_pt_4 = "In addition to our quality hands-on phlebotomy training, we offer our students payment plan options to help them afford the cost of our phlebotomy classes. We also offer our students help with securing a job after graduation. We provide our students with resume preparation, interviewing skills training, and job placement assistance upon graduation."
const description_pt_5 = "Classes are starting soon, so head over to our campus in Garland, TX and sign up for our next phlebotomy career training courses. We look forward to meeting you!"
export const description_pt = [description_pt_1, description_pt_2, description_pt_3, description_pt_4, description_pt_5]
export const requirement_pt = [req_0]
export const duration_pt = [dur_0, dur_1]

export const title_cfat = "CPR/First Aid Training"
const description_cfat_1 = "Prepare for the unexpected with first aid and training from Bah Career Training. Developed and taught by experts, our convenient, affordable course can help your organization become OSHA complaints and empower your team to provide care when it’s needed most. Classes are held at 2433 Goldfinch Lane, Garland Texas 75042 on walk-in basis with no appointment required. We offer training that’s designed to suit your schedule. All instructors are approved by American Heart. All healthcare professionals in the state of Texas are required to CPR certified and renewed every 2 years.";
const description_cfat_2 = "";
const description_cfat_3 = "";
const description_cfat_4 = "";
export const description_cfat = [description_cfat_1, description_cfat_2, description_cfat_3, description_cfat_4]
export const requirement_cfat = [req_0]
export const duration_cfat = [dur_0, dur_1]

//Sceminars
export const sceminar_title_00 = "Continuing Education Seminar for Certified Nurse's"
const sceminar_description_00_0 = "";
const sceminar_description_00_1 = "";
const sceminar_description_00_2 = "";
const sceminar_description_00_3 = "";
export const sceminar_description_00 = [sceminar_description_00_0, sceminar_description_00_1, sceminar_description_00_2, sceminar_description_00_3]
export const sceminar_requirement_00 = [req_0]
export const sceminar_duration_00 = [dur_0, dur_1]

export const sceminar_title_01 = "Assistant in the State of Texas-NATCEP In-Service Training"
const sceminar_description_01_0 = "";
const sceminar_description_01_1 = "";
const sceminar_description_01_2 = "";
const sceminar_description_01_3 = "";
export const sceminar_description_01 = [sceminar_description_01_0, sceminar_description_01_1, sceminar_description_01_2, sceminar_description_01_3]
export const sceminar_requirement_01 = [req_0]
export const sceminar_duration_01 = [dur_0, dur_1]

export const sceminar_title_02 = "Electronic Soldering Technician Seminar"
const sceminar_description_02_0 = "";
const sceminar_description_02_1 = "";
const sceminar_description_02_2 = "";
const sceminar_description_02_3 = "";
export const sceminar_description_02 = [sceminar_description_02_0, sceminar_description_02_1, sceminar_description_02_2, sceminar_description_02_3]
export const sceminar_requirement_02 = [req_0]
export const sceminar_duration_02 = [dur_0, dur_1]

export const sceminar_title_03 = "IV Training"
const sceminar_description_03_0 = "";
const sceminar_description_03_1 = "";
const sceminar_description_03_2 = "";
const sceminar_description_03_3 = "";
export const sceminar_description_03 = [sceminar_description_03_0, sceminar_description_03_1, sceminar_description_03_2, sceminar_description_03_3]
export const sceminar_requirement_03 = [req_0]
export const sceminar_duration_03 = [dur_0, dur_1]