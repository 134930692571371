import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { BrowserRouter as Router, Link as RouterLink } from "react-router-dom";
import * as HomeVar from './Home.js';
import { withStyles } from "@material-ui/core/styles";
import CustomTitle from '../../../components/CustomTitle.jsx';
import Divider from '@mui/material/Divider';
import * as Themes from '../../../components/themes/Themes.js';

export default function ServicePanel() {

     /* return (
        <>
          <CustomTitle title = {HomeVar.title_2} heading = "h4" align="left"/>
          <Divider sx={{ bgcolor: "secondary.light" }}/>

          <Typography variant="body2" color="text.secondary" align="left">
            <ul>
                  {HomeVar.description_2.map((item,i) => <li key={i}>{item}</li>)}
                </ul>
            </Typography>
        </>
      );*/

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={Themes.background_logo_bw.background_0}>
      <CustomTitle title = {HomeVar.title_2} heading = "h4" align="left"/>
      <Divider sx={{ bgcolor: "secondary.light" }}/>
        <Typography variant="body2" color="white" m={1} align="left">
        {HomeVar.description_2.map((item,i) => <li key={i}>{item}</li>)}
        </Typography>
      </AppBar>
    </Box>
  );
}