import * as React from 'react';
import ProfilePage from '../components/ProfilePage.jsx';
import * as CatalogVar from '../components/Catalogs.js';
import MyHeaders from '../../../components/headers/MyHeaders.jsx';
import MyFooter from '../../../components/footers/MyFooter.jsx';

export default function Programs_EKGT() {

  return (
    <>
      <ProfilePage title={CatalogVar.title_ekgt} description={CatalogVar.description_ekgt} requirements={CatalogVar.requirement_ekgt} duration={CatalogVar.duration_ekgt} imgURL='/catalogs/EKGTechnician.jpg'/>
    </>
  );
}