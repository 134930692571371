export const Title = "Welcome to BAH Career Training"

export const Description = "At BAH Career Training, our main focus and goal is to provide exceptional career training to students in the field of the choice." + 
                    " Our educators strive to help each student become compassionate, articulate, motivated, caring, hard-working, and competent through hands-on career training courses." + 
                    " We offer courses in many exciting fields, and we also offer many refresher and continuing education courses."

export const title_1 = "Program Titles Career Training:"
export const description_1 = [
    "Computer Maintenance Technician",
    "Dental Assistant",
    "EKG Technician",
    "Health Information Technology",
    "Nurses Aid Training Program",
    "Patient Care Technician",
    "Phlebotomy Technician",
    "CPR/First Aid Training"
]

export const title_2 = "Additional Services"
export const description_2 = [
    "Notary Services",
    "Pearson View Authorized Test Center",
    "IRS Approved Chariatable Organization [Develop Mental Learning Academy]"
]

export const title_3 = "Seminar Titles"
export const description_3 = [
    "Continuing Education Seminar for Certified Nurse's",
    "Assistant in the State of Texas-NATCEP In-Service Training",
    "Electronic Soldering Technician Seminar",
    "IV Training"
]

export const introduction = "In addition to providing high quality hands-on career training, we offer our students a variety of services that will assist them before and after graduation. We offer our students payment plans, information on financial assistance, help with resume preparation, interviewing skills training, and job placement assistance upon graduation." +
 "Additionally, BAH Career Training is also approved via the Texas Workforce Commission (TWC) Career Schools and Colleges through the WIA and DARS programs." +
 "At BAH Career Training, we want to see our students succeed, and we give them all the tools possible to make that happen. Call us today and speak to one of our helpful staff members about starting your career training today. " + "\"" + "YOUR FUTURE WAITS FOR YOU HERE AT BAH CAREER TRAINING INC!" 