import * as React from 'react';
import ProfilePage from './components/ProfilePage.jsx';
import { useState } from "react";
import * as CatalogVar from './components/Catalogs.js';

import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import ProfileCard from './components/ProfileCard.jsx';
import { Divider } from '@mui/material';
import CustomTitle from '../../components/CustomTitle.jsx';
import * as RouteValues from '../../components/RouteValues.js';
import * as Themes from '../../components/themes/Themes.js';
import MyHeaders from '../../components/headers/MyHeaders.jsx';
import MyFooter from '../../components/footers/MyFooter.jsx';

export default function Programs() {
  
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

  return (
    <div>
      <MyHeaders/>
    
        <Box sx={{ flexGrow: 1 }}>
          <Item style={Themes.background_logo_bw.main}>
            <CustomTitle title = "Browse Various Programs" heading = "h4" align="left"/>
            {/*<Divider />*/}
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{m: 1 }}>
              <Grid>
                <ProfileCard id={RouteValues.programs_cmt} learnMoreButton={true} title = {CatalogVar.title_cmt} description = {CatalogVar.description_cmt[0].substring(0, 300) + "..."} imgUrl = "/catalogs/ComputerMaintenanceTechnician.jpg" />
              </Grid>
              <Grid>
                <ProfileCard id={RouteValues.programs_da} learnMoreButton={true} title = {CatalogVar.title_da} description = {CatalogVar.description_da[0].substring(0, 300) + "..."} imgUrl = "/catalogs/DentalAssistant.jpg" />
              </Grid>
              <Grid>
                <ProfileCard id={RouteValues.programs_ekgt} learnMoreButton={true} title = {CatalogVar.title_ekgt} description = {CatalogVar.description_ekgt[0].substring(0, 300) + "..."} imgUrl = "/catalogs/EKGTechnician.jpg" />
              </Grid>
              <Grid>
                <ProfileCard id={RouteValues.programs_hit} learnMoreButton={true} title = {CatalogVar.title_hit} description = {CatalogVar.description_hit[0].substring(0, 300) + "..."} imgUrl = "/catalogs/HealthInformationTechnology.jpg" />
              </Grid>
              <Grid>
                <ProfileCard id={RouteValues.programs_natp} learnMoreButton={true} title = {CatalogVar.title_natp} description = {CatalogVar.description_natp[0].substring(0, 300) + "..."} imgUrl = "/catalogs/NurseAideTraining.png" />
              </Grid>
              <Grid>
                <ProfileCard id={RouteValues.programs_pct} learnMoreButton={true} title = {CatalogVar.title_pct} description = {CatalogVar.description_pct[0].substring(0, 300) + "..."} imgUrl = "/catalogs/PatientCareTechnician.png" />
              </Grid>
              <Grid>
                <ProfileCard id={RouteValues.programs_pt} learnMoreButton={true} title = {CatalogVar.title_pt} description = {CatalogVar.description_pt[0].substring(0, 300) + "..."} imgUrl = "/catalogs/PhlebotomyTraining.png" />
              </Grid>
              <Grid>
                <ProfileCard id={RouteValues.programs_cfat} learnMoreButton={true} title = {CatalogVar.title_cfat} description = {CatalogVar.description_cfat[0].substring(0, 300) + "..."} imgUrl = "/catalogs/CPRTraining.jpg" />
              </Grid>
            </Grid>

            <CustomTitle title = "Browse Various Sceminars" heading = "h4" align="left"/>

            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{m: 1 }}>
              <Grid>
                <ProfileCard id={RouteValues.programs_cfat} learnMoreButton={false} title = {CatalogVar.sceminar_title_00} description = {CatalogVar.sceminar_duration_00[0].substring(0, 300) + "..."} imgUrl = "/catalogs/ContinuingEducationSeminarForCertifiedNurse.jpg" />
              </Grid>
              <Grid>
                <ProfileCard id={RouteValues.programs_cfat} learnMoreButton={false} title = {CatalogVar.sceminar_title_01} description = {CatalogVar.sceminar_duration_01[0].substring(0, 300) + "..."} imgUrl = "/catalogs/AssistantInTheStateOfTexas-NATCEPIn-ServiceTraining.jpg" />
              </Grid>
              <Grid>
                <ProfileCard id={RouteValues.programs_cfat} learnMoreButton={false} title = {CatalogVar.sceminar_title_02} description = {CatalogVar.sceminar_duration_02[0].substring(0, 300) + "..."} imgUrl = "/catalogs/ElectronicSolderingTechnicianSeminar.jpg" />
              </Grid>
              <Grid>
                <ProfileCard id={RouteValues.programs_cfat} learnMoreButton={false} title = {CatalogVar.sceminar_title_03} description = {CatalogVar.sceminar_duration_03[0].substring(0, 300) + "..."} imgUrl = "/catalogs/IVTraining.jpg" />
              </Grid>
            </Grid>

          </Item>
        </Box>

      <MyFooter/>
    </div>
  );
}