import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CustomTitle from '../../../components/CustomTitle';
import Image from "material-ui-image";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AppBar, Button } from '@mui/material';
import * as RouteValues from '../../../components/RouteValues.js';
import { Link as RouterLink } from "react-router-dom";
import * as CatalogsVar from './Catalogs.js';
import * as Themes from '../../../components/themes/Themes.js';
import MyHeaders from '../../../components/headers/MyHeaders.jsx';
import MyFooter from '../../../components/footers/MyFooter.jsx';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { experimentalStyled as styled } from '@mui/material/styles';


export default function ProfilePage(props) {

  const descriptionList = props.description.map((data, index) =><p key={index} align="left">{data}</p>);
  const requirementList = props.requirements.map((data, index) =><li key={index} align="left">{data}</li>);
  const durationsList = props.duration.map((data, index) =><li key={index} align="left">{data}</li>);

  return(
    <>

    <MyHeaders/>

    <AppBar
    component="div"
    color="primary"
    position="static"
    elevation={0}
    sx={{ zIndex: 0 }}
    style={Themes.background_logo_bw.main}
  >
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <Box bgcolor="primary.light" p={2} style={{display: 'flex', flexdirection: 'row'}}>
          <Button component={RouterLink} to={RouteValues.programs}><ArrowBackIcon/></Button>
          <CustomTitle title ={props.title} heading = "h4" align="left"/>
        </Box>
      </Grid>

      <Grid item xs={8}>

      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" style={Themes.background_logo_bw.background_0}>
          <Typography variant="body2" color="White" m={1} align="left">
            {descriptionList}
              {/*<h3 align="left"> {CatalogsVar.duration} </h3>
              <ul>{durationsList}</ul>*/}
              <b align="left"> {CatalogsVar.requirements} </b>
              <ul>{requirementList}</ul>
          </Typography>
        </AppBar>
      </Box>
      </Grid>

      <Grid item xs={4}>
        <Box /*bgcolor="secondary.light"*/>

        <Image src={props.imgURL} />

        </Box>
      </Grid>

      ...
    </Grid>
    </AppBar>

    <MyFooter />
    </>
  );

  /*return (
    <Box sx={{ flexGrow: 1 }} style={Themes.background_logo_bw.main}>
      <Grid>
        <Item style={{display: 'flex', flexdirection: 'row'}}>
          <Button component={RouterLink} to={RouteValues.programs}><ArrowBackIcon/></Button>
          <CustomTitle title ={props.title} heading = "h4" align="left"/>
        </Item>
        <Divider />
      </Grid>

          <Grid container spacing={3}>
            <Grid xs>
            </Grid>
            <Grid xs={6}>
              <Item><img src={props.imgURL} style={{height:'50%', width:'100%'}}/></Item>
            </Grid>
            <Grid xs>
            </Grid>
          </Grid>

      <Grid>
        <Item style={Themes.background_logo_bw.background_0}>
        <AppBar position="static" style={Themes.background_logo_bw.background_0}>
            <Typography variant="body2" color="text.secondary" align="left">
                <Themes.WhiteTextTypography m={1}>
                  {descriptionList}
                  <h3 align="left"> {CatalogsVar.duration} </h3>
                  <ul>{durationsList}</ul>
                  <h3 align="left"> {CatalogsVar.requirements} </h3>
                  <ul>{requirementList}</ul>
                </Themes.WhiteTextTypography>
            </Typography>
          </AppBar>

        </Item>
      </Grid>
    </Box>
  );*/

  /*return (
    <>
      <div style={{  
        display: 'flex',
        flexdirection: 'row'
      }}>
        <Button component={RouterLink} to={RouteValues.programs}><ArrowBackIcon/></Button>
        <CustomTitle title ={props.title} heading = "h4" align="left"/>
      </div>
      <Divider />

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
            <Grid item xs={10}>
              <Item style={Themes.background_logo_bw.background_0}>
                {descriptionList}
              </Item>
              ...
              <Item style={Themes.background_logo_bw.background_0}>
                <h3 align="left"> {CatalogsVar.duration} </h3>
                <ul>{durationsList}</ul>
              </Item>
              ...
              <Item style={Themes.background_logo_bw.background_0}>
                <h3 align="left"> {CatalogsVar.requirements} </h3>
                <ul>{requirementList}</ul>
              </Item>
            </Grid>
            <Grid item xs={2}>
              <Item><Image src={props.imgURL} /></Item>
            </Grid>
        </Grid>
      </Box>
    </>
  );*/
}