import * as React from 'react';
import AppBar from '@mui/material/AppBar';
//import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SimpleImageSlider from "react-simple-image-slider";
import { BrowserRouter as Router, Link as RouterLink } from "react-router-dom";
import * as HomeVar from './Home.js';
import { Avatar, Grid } from '@material-ui/core';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
//import Card from '@mui/material/Card';
//import CardActions from '@mui/material/CardActions';
//import CardContent from '@mui/material/CardContent';
//import CardMedia from '@mui/material/CardMedia';
//import Button from '@mui/material/Button';
//import Typography from '@mui/material/Typography';
import FullImageCard from './FullImageCard.js';

// --- Material Ui Imports --- //
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import withStyles from "@material-ui/core/styles/withStyles";
import * as RouteValues from '../../../components/RouteValues.js';

// --- Fill Image Card Component Imports --- //
import {
  FiCard,
  FiCardActionArea,
  FiCardActions,
  FiCardContent,
  FiCardMedia
} from "./FullImageCard";

// --- Style --- //
const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  /**
   * Max Card with for demo
   * same values used in Material-Ui Card Demos
   */
  card: {
    maxWidth: 345
  },

  /**
   * Applied to Orginal Card demo
   * Same vale used in Material-ui Card Demos
   */
  media: {
    height: 140
  },

  /**
   * Demo stlying to inclrease text visibility
   * May verry on implementation
   */
  fiCardContent: {
    color: "#ffffff",
    backgroundColor: "rgba(0,0,0,.24)"
  },
  fiCardContentTextSecondary: {
    color: "rgba(255,255,255,0.78)"
  }
});

export default function ProgramListPanel() {

    const classes = useStyles();

    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3 },
    };
    
    /*const items = [
        <div className="item" data-value="1"><img src='catalogs/NurseAideTraining.png'/></div>,
        <div className="item" data-value="2"><img src='catalogs/PhlebotomyTraining.png'/></div>,
        <div className="item" data-value="3"><img src='catalogs/PatientCareTechnician.png'/></div>,
        <div className="item" data-value="4"><img src='catalogs/NurseAideTraining.png'/></div>,
        <div className="item" data-value="5"><img src='catalogs/NurseAideTraining.png'/></div>,
    ];*/

    const sliderImages = [
        {url: "catalogs/NurseAideTraining.png"},
        {url: "catalogs/PhlebotomyTraining.png"},
        {url: "catalogs/PatientCareTechnician.png"}
     ];

     const list = [
        'catalogs/NurseAideTraining.png',
        'catalogs/PhlebotomyTraining.png',
        'catalogs/PatientCareTechnician.png'
     ]

     const handleDragStart = (e) => e.preventDefault();

     const width = 500
     const height = 300

     const imgH = 400
     const imgW = 400

    const FiCard = withStyles({
        root: {
          position: "relative"
        }
      })(Card);
      
    const FiCardActionArea = withStyles({
        root: {
          position: "relative"
        }
      })(CardActionArea);
      
    const FiCardActions = withStyles({
        root: {
          position: "relative"
        }
      })(CardActions);
      
    const FiCardContent = withStyles({
        root: {
          position: "relative",
          backgroundColor: "transparent"
        }
      })(CardContent);
      
    const FiCardMedia = withStyles({
        root: {
          position: "absolute",
          top: 0,
          right: 0,
          height: "100%",
          width: "100%"
        }
      })(CardMedia);

     const items = [

        /*<Box my={4}>
            <Card className={FiCard}>
                <FiCardMedia
                    media="picture"
                    alt="Contemplative Reptile"
                    image="catalogs/ComputerMaintenanceTechnician.jpg"
                    title="Computer Maintenance Technician"
                />
                <CardContent className={FiCardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                        Lizard
                    </Typography>
                    <Typography
                    variant="body2"
                    className={classes.fiCardContentTextSecondary}
                    component="p"
                    >
                    Lizards are a widespread group of squamate reptiles, with over
                    6,000 species, ranging across all continents except Antarctica
                    </Typography>
                </CardContent>
                <CardActions className={FiCardContent}>
                    <Button size="small" color="inherit" variant="outlined">
                    Learn More
                    </Button>
                </CardActions>
            </Card>
        </Box>,


        <Card>
            <div style={{ position: "relative" }}>
            <CardMedia style={{ height: "250px", paddingTop: "2%" }}   component="img" image={"catalogs/ComputerMaintenanceTechnician.jpg"} title="Pancakes" alt="Pancakes"/> 
            <div style={{position: "absolute", color: "white",top: 10,left: "50%",transform: "translateX(-50%)",}}> Some text</div>
            </div>
        </Card>,*/


        <Grid container spacing={0} direction="column" alignItems="center" /*style={{ minHeight: '100vh' }}*/ >
            <Grid item>
                <Card>
                    <CardMedia component="img" alt="imgfsdf" height={imgH} width={imgW} image="catalogs/ComputerMaintenanceTechnician.jpg"/>
                        <CardContent><Typography gutterBottom variant="h5" component="div">Computer Maintenance Technician</Typography></CardContent>
                        <CardActions className={FiCardContent}>
                            <Button size="small" color="inherit" variant="outlined" component={RouterLink} to={RouteValues.programs_cmt}>Learn More</Button>
                        </CardActions>
                </Card>
            </Grid>      
        </Grid>,

        <Grid container spacing={0} direction="column" alignItems="center" /*style={{ minHeight: '100vh' }}*/ >
        <Grid item>
            <Card>
                <CardMedia component="img" alt="imgfsdf" height={imgH} width={imgW} image="catalogs/DentalAssistant.jpg"/>
                    <CardContent><Typography gutterBottom variant="h5" component="div">Dental Assistant</Typography></CardContent>
                    <CardActions className={FiCardContent}>
                        <Button size="small" color="inherit" variant="outlined" component={RouterLink} to={RouteValues.programs_da}>Learn More</Button>
                    </CardActions>
            </Card>
        </Grid>      
        </Grid>,

        <Grid container spacing={0} direction="column" alignItems="center" /*style={{ minHeight: '100vh' }}*/ >
        <Grid item>
            <Card>
                <CardMedia component="img" alt="imgfsdf" height={imgH} width={imgW} image="catalogs/EKGTechnician.jpg"/>
                    <CardContent><Typography gutterBottom variant="h5" component="div">EKG Technician</Typography></CardContent>
                    <CardActions className={FiCardContent}>
                        <Button size="small" color="inherit" variant="outlined" component={RouterLink} to={RouteValues.programs_ekgt}>Learn More</Button>
                    </CardActions>
            </Card>
        </Grid>      
        </Grid>,

        <Grid container spacing={0} direction="column" alignItems="center" /*style={{ minHeight: '100vh' }}*/ >
        <Grid item>
            <Card>
                <CardMedia component="img" alt="imgfsdf" height={imgH} width={imgW} image="catalogs/HealthInformationTechnology.jpg"/>
                    <CardContent><Typography gutterBottom variant="h5" component="div">Health Information Technology</Typography></CardContent>
                    <CardActions className={FiCardContent}>
                        <Button size="small" color="inherit" variant="outlined" component={RouterLink} to={RouteValues.programs_hit}>Learn More</Button>
                    </CardActions>
            </Card>
        </Grid>      
        </Grid>,

        <Grid container spacing={0} direction="column" alignItems="center" /*style={{ minHeight: '100vh' }}*/ >
        <Grid item>
            <Card>
                <CardMedia component="img" alt="imgfsdf" height={imgH} width={imgW} image="catalogs/NurseAideTraining.png"/>
                    <CardContent><Typography gutterBottom variant="h5" component="div">Nurse Aide Training</Typography></CardContent>
                    <CardActions className={FiCardContent}>
                        <Button size="small" color="inherit" variant="outlined" component={RouterLink} to={RouteValues.programs_natp}>Learn More</Button>
                    </CardActions>
            </Card>
        </Grid>      
        </Grid>,

        <Grid container spacing={0} direction="column" alignItems="center" /*style={{ minHeight: '100vh' }}*/ >
        <Grid item>
            <Card>
                <CardMedia component="img" alt="imgfsdf" height={imgH} width={imgW} image="catalogs/PatientCareTechnician.png"/>
                    <CardContent><Typography gutterBottom variant="h5" component="div">Patient Care Technician</Typography></CardContent>
                    <CardActions className={FiCardContent}>
                        <Button size="small" color="inherit" variant="outlined" component={RouterLink} to={RouteValues.programs_pct}>Learn More</Button>
                    </CardActions>
            </Card>
        </Grid>      
        </Grid>,

        <Grid container spacing={0} direction="column" alignItems="center" /*style={{ minHeight: '100vh' }}*/ >
        <Grid item>
            <Card>
                <CardMedia component="img" alt="imgfsdf" height={imgH} width={imgW} image="catalogs/PhlebotomyTraining.png"/>
                    <CardContent><Typography gutterBottom variant="h5" component="div">Phlebotomy Training</Typography></CardContent>
                    <CardActions className={FiCardContent}>
                        <Button size="small" color="inherit" variant="outlined" component={RouterLink} to={RouteValues.programs_pt}>Learn More</Button>
                    </CardActions>
            </Card>
        </Grid>      
        </Grid>,

        <Grid container spacing={0} direction="column" alignItems="center" /*style={{ minHeight: '100vh' }}*/ >
        <Grid item>
            <Card>
                <CardMedia component="img" alt="imgfsdf" height={imgH} width={imgW} image="catalogs/CPRTraining.jpg"/>
                    <CardContent><Typography gutterBottom variant="h5" component="div">CPR/First Aid Training</Typography></CardContent>
                    <CardActions className={FiCardContent}>
                        <Button size="small" color="inherit" variant="outlined" component={RouterLink} to={RouteValues.programs_cfat}>Learn More</Button>
                    </CardActions>
            </Card>
        </Grid>      
        </Grid>,

     ]

    /*const items = [
        <Avatar variant={"rounded"} style={{width: width, height: height, margin: "auto"}} alt="The image" src='catalogs/ComputerMaintenanceTechnician.jpg'/>,
        <Avatar variant={"rounded"} style={{width: width, height: height, margin: "auto"}} alt="The image" src='catalogs/DentalAssistant.jpg'/>,
        <Avatar variant={"rounded"} style={{width: width, height: height, margin: "auto"}} alt="The image" src='catalogs/EKGTechnician.jpg'/>,
        <Avatar variant={"rounded"} style={{width: width, height: height, margin: "auto"}} alt="The image" src='catalogs/HealthInformationTechnology.jpg'/>,
        <Avatar variant={"rounded"} style={{width: width, height: height, margin: "auto"}} alt="The image" src='catalogs/NurseAideTraining.png'/>,
        <Avatar variant={"rounded"} style={{width: width, height: height, margin: "auto"}} alt="The image" src='catalogs/PatientCareTechnician.png'/>,
        <Avatar variant={"rounded"} style={{width: width, height: height, margin: "auto"}} alt="The image" src='catalogs/PhlebotomyTraining.png'/>,
        <Avatar variant={"rounded"} style={{width: width, height: height, margin: "auto"}} alt="The image" src='catalogs/CPRTraining.jpg'/>
    ];*/

     return(
        <div>
            <AliceCarousel 
            autoPlay='true'
            autoPlayInterval={2500}
            animationDuration={1000}
            infinite
            disableButtonsControls
            controlsStrategy="alternate" 
            mouseTracking items={items} 
            />
        </div>
     );

     /*return (
        <div>
            <SimpleImageSlider 
                width="80%" 
                height="50%" 
                images={list} 
                showBullets={true} 
                showNavs={true}
            />
        </div>
     );*/
  }