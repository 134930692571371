import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ProfilePage from './ProfilePage';
import { BrowserRouter as Router, Link as RouterLink } from "react-router-dom";

export default function ProfileCard(props) {

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={props.imgUrl}
        title=""//on hover value
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {props.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {props.description}
        </Typography>
      </CardContent>
      <CardActions>
        {props.learnMoreButton && <Button size="small" component={RouterLink} to={props.id}>Learn More</Button>}
      </CardActions>
    </Card>
  );
}
