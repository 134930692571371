import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Paper, Card, CardContent } from '@mui/material';
import * as Themes from '../../components/themes/Themes';
import CustomTitle from '../../components/CustomTitle';
import Divider from '@mui/material/Divider';

const defaultTheme = createTheme();

export default function ContactUs() {

    let name = '';
    let email = '';
    let subject = '';
    let message = '';

    const SetName = (value) => {
        name = value;
    }

    const SetEmail = (value) => {
        email = value;
    }

    const SetSubject = (value) => {
        subject = value;
    }

    const SetMessage = (value) => {
        message = value;
    }

    const handleSubmit = () => {
    console.log(
        'name: ' + name + '\n',
        'email: ' + email + '\n',
        'subject: ' + subject + '\n',
        'message: ' + message + '\n'
    )
  };

  return (
    <>
    <Paper theme={defaultTheme} style={Themes.background_logo_bw.main}>

        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

        <CustomTitle title = {'Contact Us'} heading = "h4" align="left"/>
        <Divider />

        <Card>
        <CardContent>

            <TextField //name
              margin="normal"
              required
              fullWidth
              id="name"
              label="First/Last Name"
              name="name"
              autoFocus
              onChange={e => SetName(e.target.value)}
            />
            <TextField //email
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={e => SetEmail(e.target.value)}
            />
            <TextField //subject
              margin="normal"
              required
              fullWidth
              id="subject"
              label="Subject"
              name="subject"
              autoFocus
              onChange={e => SetSubject(e.target.value)}
            />
            <TextField //message
              margin="normal"
              required
              fullWidth
              name="message"
              label="message"
              type="message"
              id="message"
              multiline={true} 
              minRows={7}
              onChange={e => SetMessage(e.target.value)}
            />
            <Button type='submit' fullWidth variant="contained" sx={{ mt: 2 }} onClick={handleSubmit}>Submit</Button>

        </CardContent>
        </Card>

        </Box>

    </Paper>
    </>
  );
}

/*import React, {Component} from 'react'
import {Button, TextField, FormControl, Container, Paper, Box, Grid} from '@material-ui/core'
import * as Themes from '../../components/themes/Themes';

class ContactUs extends Component {
    render () {

        let message = '';

        const SetMessage = (value) => {
            message = value;
        }

        return (
            <Box   
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            style={Themes.background_logo_bw.main}
            >
                

                <Grid container direction="row" justifyContent="center">
                    <form className="centerMyForm">
                            <Box> 
                                <Paper><TextField label="Name"/></Paper>
                            </Box>
                            <Box>
                                <TextField id="outlined-basic" variant="outlined" color='primary' size='large' label="Email"/>
                            </Box>
                            <Box>
                                <Paper>
                                    <TextField id="outlined-basic" variant="outlined" label="Subject"/>
                                </Paper>
                                
                            </Box>
                            <Box>
                                <Paper>
                                    <TextField id="outlined-basic" variant="outlined" label="Message"  placeholder='First/Last Name' onChange={e => SetMessage(e.target.value)}/>
                                </Paper>
                            </Box>
                            <Box>
                                <Button variant="contained" color="primary">Submit</Button>
                            </Box>
                    </form>
                </Grid> 

            </Box>
  
        )
    }
}

export default ContactUs*/