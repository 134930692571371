import * as React from 'react';
import ProfilePage from '../components/ProfilePage.jsx';
import * as CatalogVar from '../components/Catalogs.js';
import MyHeaders from '../../../components/headers/MyHeaders.jsx';
import MyFooter from '../../../components/footers/MyFooter.jsx';

export default function Programs_PT() {

  return (
    <>
      <ProfilePage title={CatalogVar.title_pt} description={CatalogVar.description_pt} requirements={CatalogVar.requirement_pt} duration={CatalogVar.duration_pt} imgURL='/catalogs/PhlebotomyTraining.png'/>
    </>
  );
}